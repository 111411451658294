@import 'style.css';
:root {
  --red_color: #d63835;
  --black_color: #333333;
  --primary_red: #d73836;
  --delete_red: #fd6360;
}

::-webkit-scrollbar {
  width: 7.5px;
  height: 7.5px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: var(--red_color);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--black_color);
}

.content {
  background-color: #ffffff;
  margin-left: 10px;
  padding-right: 20px;
  min-height: 80vh;
}

.center {
  text-align: center;
}

@media only screen and (max-width: 1024px) {
  span.menu-toggle {
    margin-right: 10px;
  }

  div.invoice-button {
    margin: 0 0 15px 0 !important;
  }

  div.react-datepicker-wrapper {
    width: 100% !important;
  }

  div.react-datepicker__input-container {
    width: 100% !important;
  }

  .nyumbani_button {
    width: 100%;
    margin-top: 5px;
  }

  button + button {
    margin-left: 0px !important;
  }

  div.right {
    padding-right: 0px !important;
  }

  .content {
    margin-left: 0;
    padding: 0 20px 0 20px;
  }

  .master-header {
    margin-left: 0;
  }

  .property-images {
    margin-bottom: 50px;
  }

  .bm-responsive-div {
    width: 100%;
  }

  .input-icon {
    width: 100%;
  }

  .small-button {
    width: 100%;
  }

  div#dashboard-header {
    display: none;
  }
}

.small-button {
  margin: 10px;
}

table.export {
  border-collapse: collapse;
  empty-cells: show;
  border-width: 0.5px !important;
  border-top: 1px solid var(--red_color) !important;
  border-bottom: 1px solid var(--red_color) !important;
  border-left: 1px solid var(--red_color) !important;
  width: 100% !important;
}

table.export tbody td {
  border-top: 0.5px solid var(--red_color) !important;
  border-right: 0.5px solid var(--red_color) !important;
  padding: 4px 7px 2px 10px !important;
}

table.export th {
  border-bottom: none !important;
  border-right: 0.5px solid var(--red_color) !important;
  padding: 4px 7px 2px 10px !important;
}

.bordered_photo {
  cursor: pointer;
  transition: 0.3s;
  border: solid 1px var(--red_color);
}

.bordered_photo:hover {
  border: solid 1px var(--red_color);
  border-radius: 30px;
  cursor: pointer;
  transition: 0.3s;
  opacity: 0.9;
}

button + button {
  margin-left: 10px;
}

ul.nav > li > a > span {
  color: var(--primary_red);
  font-size: 15px;
}

ul.nav > li > a > span:hover {
  color: var(--black_color);
}

ul.nav > li.active {
  color: var(--black_color);
}

.product-name {
  color: var(--primary_red) !important;
  font-size: 20px !important;
  cursor: pointer;
}

.primary-red {
  color: var(--primary_red) !important;
}

.navbar-icons {
  color: var(--primary_red) !important;
}

.navbar-icons:hover {
  color: var(--black_color) !important;
}

ul.nav > li > ul.nav-second-level > li > a {
  color: var(--black_color) !important;
  font-size: 12px;
}

ul.nav > li ul.nav-second-level > li > a:hover {
  color: var(--primary_red) !important;
}

ul.nav > li ul.nav-second-level > li.active > a {
  color: var(--primary_red) !important;
}

/* ul#side-menu li {
  background-color: #ccc;
  margin-top: 10px;
} */

/* li.main-menu {
  margin-top: 5px;
}

li.main-menu.active {
  background-color: var(--primary_red) !important;
  color: #ffffff !important;
} */

.header {
  line-height: 28px;
  margin-bottom: 16px;
  margin-top: 18px;
  padding-bottom: 4px;
  border-bottom: 1px solid #ccc;
}

.header.red {
  border-bottom-color: #f3cdc6;
}

h3.smaller {
  font-size: 21px;
}

.lighter {
  font-weight: lighter;
}

.hidden {
  display: none;
}

.logout-span {
  margin-left: 20px;
  vertical-align: top;
}

button {
  border: none !important;
  border-color: #ffffff !important;
  outline: none !important;
}

button:focus {
  border: none !important;
  border-color: #ffffff !important;
  outline: none !important;
}

button:hover {
  border: none !important;
  border-color: #ffffff !important;
  outline: none !important;
}

button:active {
  border: none !important;
  border-color: #ffffff !important;
  outline: none !important;
}

.nyumbani-logo {
  margin-top: -30px !important;
  height: 115px;
}

#logo.light-version {
  height: 103px !important;
}

ul#side-menu {
  padding-top: 70px !important;
}

div#header {
  box-shadow: none !important;
}

div#dashboard-header-panel {
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.1);
}

aside#menu {
  background-color: #ffffff !important;
}

div#wrapper {
  background-color: #ffffff !important;
}

div.inline-right-button button {
  margin-top: 15px !important;
}

div.right {
  text-align: right;
  padding-right: 15px;
}

div.invoice-button {
  margin-top: 15px;
}

div.react-datepicker-wrapper {
  width: 100% !important;
}

div.react-datepicker__input-container {
  width: 100% !important;
}

label.checkbox-container + label.checkbox-container {
  margin-left: 15px;
}
